import {
  createReducer,
  on,
  Action,
  ActionReducer,
  ActionType,
} from "@ngrx/store";
import { OnReducer } from "@ngrx/store/src/reducer_creator";

import { Payload } from "@shared/interfaces/store";
import { GetFromState } from "@shared/store/types/reducer.types";
import { StorageService } from "@core/services/storage.service";
import { ICDLLists } from "@shared/interfaces/manage-account-lists";
import { IServerError } from "@shared/interfaces/server-error";
import { PermissionsKeys } from "@core/constants/permissions";
import * as actions from "../actions/profile.action";

import { MVEntityAvailable } from "../../utils/mv-entity-available";

import { IRole, IUser, IUserTransformed } from "../../../auth/interfaces/user";
import { IMVEntityAvailable } from "../../interfaces/mv-entity-available";
import {
  IAccountPermissionsForList,
  ILoggedInAndUser,
  IProfileTabTitle,
  IUserPermissions,
} from "../../interfaces/profile";
import { IProfileSetting } from "../../interfaces/profile-settings";

import { ACCOUNT_MESSAGES } from "../../constants/messages";
import { ROLES_CONFIG } from "../../constants/roles";

interface IChangePasswordSuccessMessage {
  message: string;
}

export interface IProfileState {
  user: IUser | null;

  role: IRole;

  isAdmin: boolean;
  isBroker: boolean;
  isAdminsAccount: boolean;

  isUnlimited: boolean;

  isFirstAssignAvailable: boolean;

  isTokenExpired: boolean;

  profileLoading: boolean;
  profileUpdating: boolean;
  changePasswordLoading: boolean;

  profileUpdateSuccessMessage: string | null;
  changePasswordSuccessMessage: IChangePasswordSuccessMessage;

  profileError: IServerError | null;
  updateProfileError: IServerError | null;
  changePasswordError: IServerError | null;

  profileSettingsItems: IProfileSetting[];
  profileSettingsError: IServerError | null;
  loadingProfileSettings: boolean;
  updatingProfileSettings: boolean;

  hasLists: boolean | null;
  permissions: string[];
  accountAvailablePermissions: string[];

  hasAvailableCloudSync: boolean;
  showOms: boolean;

  cdlLists: ICDLLists | null;
}

const initialState: IProfileState = {
  user: StorageService.user,

  isAdmin: StorageService.isAdmin,
  isBroker: StorageService.isBroker,
  role: StorageService.role,
  isAdminsAccount: StorageService.isAdminsAccount,

  isUnlimited: StorageService.isUnlimited,

  isFirstAssignAvailable: StorageService.isFirstAssignAvailable,

  isTokenExpired: false,

  profileLoading: false,
  profileUpdating: false,
  changePasswordLoading: false,

  profileUpdateSuccessMessage: null,
  changePasswordSuccessMessage: null,

  profileError: null,
  updateProfileError: null,
  changePasswordError: null,

  profileSettingsItems: [],
  profileSettingsError: null,
  loadingProfileSettings: false,
  updatingProfileSettings: false,

  hasLists: StorageService.hasLists,
  permissions: StorageService.permissions,
  accountAvailablePermissions: StorageService.accountAvailablePermissions,

  hasAvailableCloudSync: false,
  showOms: false,

  cdlLists: StorageService.cdlLists,
};

// GET PROFILE
const getProfileHandler: OnReducer<IProfileState, ActionType<any>> = (
  state: IProfileState,
) => ({
  ...state,
  profileLoading: true,
  profileError: null,
});

const getProfileSuccessHandler: OnReducer<
  IProfileState,
  ActionType<Payload<any>>
> = (state: IProfileState, { payload }: Payload<any>) => ({
  ...state,
  user: {
    ...payload.user,
    postalCode: payload.user ? payload.user.postalCode.toString() : null,
  },
  isAdmin: payload.isAdmin,
  isBroker: payload.isBroker,
  isAdminsAccount: payload.isAdminsAccount,
  isUnlimited: payload.isUnlimited,
  hasLists: payload.hasLists,
  role: payload.role,
  permissions: payload.permissions ? [...payload.permissions] : [],
  accountAvailablePermissions: payload.accountAvailablePermissions
    ? [...payload.accountAvailablePermissions]
    : [],
  profileLoading: false,
  profileError: null,
  isFirstAssignAvailable: payload.isFirstAssignAvailable,
  isTokenExpired: payload.tokenExpired,
  hasAvailableCloudSync: payload.hasAvailableCloudSync,
  showOms: payload.showOms,
  cdlLists: payload.cdlLists,
});

const getProfileErrorHandler: OnReducer<
  IProfileState,
  ActionType<Payload<any>>
> = (state: IProfileState, { payload }: Payload<any>) => ({
  ...state,
  profileLoading: false,
  profileError: { ...payload },
});

// UPDATE FIRST ASSIGN AVAILABLE
const updateIsFirstAssignAvailableHandler: OnReducer<
  IProfileState,
  ActionType<Payload<any>>
> = (state: IProfileState, { payload }: Payload<any>) => ({
  ...state,
  isFirstAssignAvailable: payload,
});

// SET PROFILE
const setProfileHandler: OnReducer<IProfileState, ActionType<Payload<any>>> = (
  state: IProfileState,
  { payload }: Payload<any>,
) => ({
  ...state,
  user: {
    ...payload.user,
    postalCode: payload.user ? payload.user.postalCode.toString() : null,
  },
  isAdmin: payload.isAdmin,
  isBroker: payload.isBroker,
  isAdminsAccount: payload.isAdminsAccount,
  isUnlimited: payload.isUnlimited,
  hasLists: payload.hasLists,
  role: { ...payload.role },
  permissions: payload.permissions ? [...payload.permissions] : [],
  accountAvailablePermissions: payload.accountAvailablePermissions
    ? [...payload.accountAvailablePermissions]
    : [],
  cdlLists: payload.cdlLists,
});

// UPDATE PROFILE
const updateProfileHandler: OnReducer<IProfileState, ActionType<any>> = (
  state: IProfileState,
) => ({
  ...state,
  profileUpdating: true,
  updateProfileError: null,
  profileUpdateSuccessMessage: null,
});

const updateProfileSuccessHandler: OnReducer<
  IProfileState,
  ActionType<Payload<any>>
> = (state: IProfileState, { payload }: Payload<any>) => ({
  ...state,
  user: {
    ...state.user,
    ...payload.user,
    postalCode:
      (payload.user ? payload.user.postalCode.toString() : null) ||
      state.user.postalCode,
  },
  profileUpdating: false,
  updateProfileError: null,
  profileUpdateSuccessMessage: ACCOUNT_MESSAGES.CHANGE_SUCCESS,
});

const setIsBroker: OnReducer<IProfileState, ActionType<Payload<any>>> = (
  state: IProfileState,
  { payload }: Payload<any>,
) => ({
  ...state,
  isBroker: payload,
});

const updateProfileErrorHandler: OnReducer<
  IProfileState,
  ActionType<Payload<any>>
> = (state: IProfileState, { payload }: Payload<any>) => ({
  ...state,
  profileUpdating: false,
  updateProfileError: { ...payload },
  profileUpdateSuccessMessage: null,
});

// permissions
const updateProfilePermissionHandler: OnReducer<
  IProfileState,
  ActionType<Payload<any>>
> = (state: IProfileState, { payload }: Payload<any>) => ({
  ...state,
  permissions: [...payload.permissions],
  accountAvailablePermissions: [...payload.accountAvailablePermissions],
});

const updateProfileRoleHandler: OnReducer<
  IProfileState,
  ActionType<Payload<any>>
> = (state: IProfileState, { payload }: Payload<any>) => ({
  ...state,
  role: { ...payload },
});

// CHANGE PASSWORD
const changePasswordHandler: OnReducer<IProfileState, ActionType<any>> = (
  state: IProfileState,
) => ({
  ...state,
  changePasswordLoading: true,
  changePasswordError: null,
  changePasswordSuccessMessage: null,
});

const changePasswordSuccessHandler: OnReducer<
  IProfileState,
  ActionType<Payload<any>>
> = (state: IProfileState, { payload }: Payload<any>) => ({
  ...state,
  changePasswordLoading: false,
  changePasswordError: null,
  changePasswordSuccessMessage: payload,
});

const changePasswordErrorHandler: OnReducer<
  IProfileState,
  ActionType<Payload<any>>
> = (state: IProfileState, { payload }: Payload<any>) => ({
  ...state,
  changePasswordLoading: false,
  changePasswordError: { ...payload },
  changePasswordSuccessMessage: null,
});

const resetPasswordStateHandler: OnReducer<IProfileState, ActionType<any>> = (
  state: IProfileState,
) => ({
  ...state,
  changePasswordLoading: false,
  changePasswordError: null,
  changePasswordSuccessMessage: null,
});

// CLEAR PROFILE
const clearProfileHandler: OnReducer<IProfileState, ActionType<any>> = (
  state: IProfileState,
) => ({
  ...state,
  user: null,
  isAdmin: false,
  isUnlimited: false,
  role: null,
  hasAvailableCloudSync: false,
});

// GET PROFILE SETTINGS
const getProfileSettingsHandler: OnReducer<IProfileState, ActionType<any>> = (
  state: IProfileState,
) => ({
  ...state,
  loadingProfileSettings: true,
});

const getProfileSettingsSuccessHandler: OnReducer<
  IProfileState,
  ActionType<Payload<any>>
> = (state: IProfileState, { payload }: Payload<any>) => ({
  ...state,
  loadingProfileSettings: false,
  profileSettingsError: null,
  profileSettingsItems: [...payload],
});

const getProfileSettingsErrorHandler: OnReducer<
  IProfileState,
  ActionType<Payload<any>>
> = (state: IProfileState, { payload }: Payload<any>) => ({
  ...state,
  loadingProfileSettings: false,
  profileSettingsError: { ...payload },
});

const resetProfileSettingsHandler: OnReducer<IProfileState, ActionType<any>> = (
  state: IProfileState,
) => ({
  ...state,
  loadingProfileSettings: false,
  profileSettingsError: null,
  profileSettingsItems: [],
  updatingProfileSettings: false,
});

// UPDATE PROFILE SETTINGS
const updateProfileSettingsHandler: OnReducer<
  IProfileState,
  ActionType<any>
> = (state: IProfileState) => ({
  ...state,
  updatingProfileSettings: true,
});

const updateProfileSettingsSuccessHandler: OnReducer<
  IProfileState,
  ActionType<Payload<any>>
> = (state: IProfileState, { payload }: Payload<any>) => {
  const profileSettingsItems: IProfileSetting[] =
    state.profileSettingsItems.map((item: any) => {
      if (item.id === payload.id) {
        return { ...item, value: payload.value };
      }
      return item;
    });

  return {
    ...state,
    profileSettingsError: null,
    updatingProfileSettings: false,
    profileSettingsItems,
  };
};

const updateProfileSettingsErrorHandler: OnReducer<
  IProfileState,
  ActionType<Payload<any>>
> = (state: IProfileState, { payload }: Payload<any>) => ({
  ...state,
  profileSettingsError: { ...payload },
  updatingProfileSettings: false,
});

// RESET ERRORS
const resetErrorsHandler: OnReducer<IProfileState, ActionType<any>> = (
  state: IProfileState,
) => ({
  ...state,
  updateProfileError: null,
});

const reducer: ActionReducer<IProfileState> = createReducer<IProfileState>(
  initialState,

  on(actions.getProfileAction, getProfileHandler),
  on(actions.getProfileErrorAction, getProfileErrorHandler),
  on(actions.getProfileSuccessAction, getProfileSuccessHandler),

  on(
    actions.updateIsFirstAssignAvailableAction,
    updateIsFirstAssignAvailableHandler,
  ),

  on(actions.setProfileAction, setProfileHandler),

  on(actions.updateProfileAction, updateProfileHandler),
  on(actions.updateProfileErrorAction, updateProfileErrorHandler),
  on(actions.updateProfileSuccessAction, updateProfileSuccessHandler),

  on(actions.setIsBrokerAction, setIsBroker),

  on(actions.updateProfileAndBillingInfoAction, updateProfileHandler),
  on(actions.updateProfileAndBillingInfoErrorAction, updateProfileErrorHandler),
  on(
    actions.updateProfileAndBillingInfoSuccessAction,
    updateProfileSuccessHandler,
  ),

  on(actions.changeProfilePermissionAction, updateProfilePermissionHandler),
  on(actions.updateProfileRoleAction, updateProfileRoleHandler),

  on(actions.changePasswordAction, changePasswordHandler),
  on(actions.changePasswordErrorAction, changePasswordErrorHandler),
  on(actions.changePasswordSuccessAction, changePasswordSuccessHandler),

  on(actions.resetPasswordStateAction, resetPasswordStateHandler),

  on(actions.clearProfileAction, clearProfileHandler),

  on(actions.getProfileSettingsAction, getProfileSettingsHandler),
  on(actions.getProfileSettingsErrorAction, getProfileSettingsErrorHandler),
  on(actions.getProfileSettingsSuccessAction, getProfileSettingsSuccessHandler),

  on(actions.resetProfileSettingsAction, resetProfileSettingsHandler),

  on(actions.updateProfileSettingsAction, updateProfileSettingsHandler),
  on(
    actions.updateProfileSettingsErrorAction,
    updateProfileSettingsErrorHandler,
  ),
  on(
    actions.updateProfileSettingsSuccessAction,
    updateProfileSettingsSuccessHandler,
  ),

  on(actions.resetErrorsAction, resetErrorsHandler),
);

export function profileReducer(
  state: IProfileState,
  action: Action,
): IProfileState {
  return reducer(state, action);
}

export const user: GetFromState<IUser, IProfileState> = (
  state: IProfileState,
): IUser => state.user;
export const userName: GetFromState<string, IUser> = (_user: IUser): string =>
  _user && _user.firstName;
export const subscription: GetFromState<boolean, IUser> = (
  _user: IUser,
): boolean => (_user && _user.subscription === 0 ? false : true);
export const fullUserName: GetFromState<string, IUser> = (
  _user: IUser,
): string => `${_user.firstName} ${_user.lastName}`;
export const userId: GetFromState<number, IUser> = (_user: IUser): number =>
  _user && _user.id;
export const userIsLoaded: GetFromState<boolean, IUser> = (
  _user: IUser,
): boolean => !!_user;
export const allowCustomList: GetFromState<boolean, IUser> = (
  _user: IUser,
): boolean => _user && _user.allowCustomList;
export const isLoggedInAndUser: GetFromState<
  ILoggedInAndUser,
  boolean,
  IUser
> = (isLoggedIn: boolean, _user: IUser): ILoggedInAndUser => ({
  isLoggedIn,
  user: _user,
});
export const isAdmin: GetFromState<boolean, IProfileState> = (
  state: IProfileState,
): boolean => state && state.isAdmin;
export const isBroker: GetFromState<boolean, IProfileState> = (
  state: IProfileState,
): boolean => state.isBroker;
export const isAdminsAccount: GetFromState<boolean, IProfileState> = (
  state: IProfileState,
): boolean => state && state.isAdminsAccount;
export const isOwnerOfAdminsAccount: GetFromState<boolean, IProfileState> = (
  state: IProfileState,
): boolean => state && !state.isAdmin && state.isAdminsAccount;
export const isUnlimited: GetFromState<boolean, IProfileState> = (
  state: IProfileState,
): boolean => state && state.isUnlimited;
export const isFirstAssignAvailable: GetFromState<boolean, IProfileState> = (
  state: IProfileState,
): boolean => state.isFirstAssignAvailable;
export const isTokenExpired: GetFromState<boolean, IProfileState> = (
  state: IProfileState,
): boolean => state.isTokenExpired;
export const profileUpdating: GetFromState<boolean, IProfileState> = (
  state: IProfileState,
): boolean => state.profileUpdating;
export const profileUpdateSuccessMessage: GetFromState<
  string | null,
  IProfileState
> = (state: IProfileState): string | null => state.profileUpdateSuccessMessage;
export const profileSettings: GetFromState<IProfileSetting[], IProfileState> = (
  state: IProfileState,
): IProfileSetting[] => state.profileSettingsItems;
export const updatingProfileSetting: GetFromState<boolean, IProfileState> = (
  state: IProfileState,
): boolean => state && state.updatingProfileSettings;
export const updateProfileError: GetFromState<
  IServerError | null,
  IProfileState
> = (state: IProfileState): IServerError | null => state.updateProfileError;
export const changePasswordLoading: GetFromState<boolean, IProfileState> = (
  state: IProfileState,
): boolean => state.changePasswordLoading;
export const changePasswordError: GetFromState<
  IServerError | null,
  IProfileState
> = (state: IProfileState): IServerError | null => state.changePasswordError;
export const changePasswordSuccessMessage: GetFromState<
  string,
  IProfileState
> = (state: IProfileState): string =>
  state.changePasswordSuccessMessage
    ? state.changePasswordSuccessMessage.message
    : "";
export const role: GetFromState<IRole, IProfileState> = (
  state: IProfileState,
): IRole => state.role;
export const roleKey: GetFromState<string, IRole> = (_role: IRole): string =>
  _role && _role.key;
export const roleKeyConfig: GetFromState<IProfileTabTitle, string> = (
  key: string,
): IProfileTabTitle => ROLES_CONFIG[key];
export const permissions: GetFromState<string[], IProfileState> = (
  state: IProfileState,
): string[] => state.permissions;
export const accountAvailablePermissions: GetFromState<
  string[],
  IProfileState
> = (state: IProfileState): string[] => state.accountAvailablePermissions;
export const companyName: GetFromState<string, IUser> = (
  _user: IUser,
): string => _user && _user.company;
export const userCountryCode: GetFromState<string | null, IUser> = (
  _user: IUser,
): string | null => (_user && _user.countryCode) || null;
export const transformedUserData: GetFromState<IUserTransformed, IUser> = (
  userData: IUser,
): IUserTransformed => {
  if (!userData) {
    return null;
  }

  const {
    email,
    prefix,
    firstName,
    lastName,
    company,
    phone,
    addressFirst,
    addressSecond,
    city,
    stateCode,
    countryCode,
    postalCode,
  }: IUser = userData;

  return {
    email,
    prefix,
    firstName,
    lastName,
    company,
    phone,
    address: {
      addressFirst,
      addressSecond,
      city,
      stateCode,
      countryCode,
      postalCode: postalCode ? postalCode.toString() : "",
    },
  } as IUserTransformed;
};

export const userIsHasAddress: GetFromState<boolean, IUser> = (
  userData: IUser,
): boolean =>
  userData &&
  !!userData.addressFirst &&
  !!userData.city &&
  !!userData.company &&
  !!userData.phone &&
  !!userData.postalCode &&
  !!userData.countryCode;
export const canCheckoutTos: GetFromState<
  boolean,
  boolean,
  boolean,
  boolean,
  boolean
> = (
  isLoggedIn: boolean,
  userExist: boolean,
  _isAdmin: boolean,
  _isAdminsAccount: boolean,
): boolean => isLoggedIn && userExist && !_isAdmin && !_isAdminsAccount;

export const hasAvailableCloudSync: GetFromState<boolean, IProfileState> = (
  state: IProfileState,
): boolean => state && state.hasAvailableCloudSync;
export const showOms: GetFromState<boolean, IProfileState> = (
  state: IProfileState,
): boolean => state && state.showOms;

export const canManageViews: GetFromState<boolean, string[]> = (
  _permissions: string[],
): boolean => _permissions.includes(PermissionsKeys.ManageViews);
export const canManageQuickSearch: GetFromState<boolean, string[]> = (
  _permissions: string[],
): boolean => _permissions.includes(PermissionsKeys.ManageQuickSearch);
export const canManageGrants: GetFromState<boolean, string[]> = (
  _permissions: string[],
): boolean => _permissions.includes(PermissionsKeys.ManageGrants);
export const canManageBids: GetFromState<boolean, string[]> = (
  _permissions: string[],
): boolean => _permissions.includes(PermissionsKeys.ManageBids);

export const userPermissions: GetFromState<
  IUserPermissions,
  IRole,
  string[],
  string[]
> = (
  _role: IRole,
  _permissions: string[],
  _accountPermissions: string[],
): IUserPermissions => ({
  role: _role,
  permissions: _permissions,
  accountPermissions: _accountPermissions,
});

export const marketViewAvailable: GetFromState<
  IMVEntityAvailable,
  IUserPermissions,
  { isHidden: boolean }
> = (
  data: IUserPermissions,
  { isHidden }: { isHidden: boolean } = { isHidden: false },
): IMVEntityAvailable =>
  new MVEntityAvailable(PermissionsKeys.ManageViews, data, isHidden);

export const grantAvailable: GetFromState<
  IMVEntityAvailable,
  IUserPermissions,
  { isHidden: boolean }
> = (
  data: IUserPermissions,
  { isHidden }: { isHidden: boolean } = { isHidden: false },
): IMVEntityAvailable =>
  new MVEntityAvailable(PermissionsKeys.ManageGrants, data, isHidden);

export const bidAvailable: GetFromState<
  IMVEntityAvailable,
  IUserPermissions,
  { isHidden: boolean }
> = (
  data: IUserPermissions,
  { isHidden }: { isHidden: boolean } = { isHidden: false },
): IMVEntityAvailable =>
  new MVEntityAvailable(PermissionsKeys.ManageBids, data, isHidden);

export const cdlListAvailable: GetFromState<ICDLLists, IProfileState> = (
  state: IProfileState,
): ICDLLists => state && state.cdlLists;

export const accountPermissionsForList: GetFromState<
  IAccountPermissionsForList,
  boolean,
  boolean,
  ICDLLists
> = (
  isAdminAccount: boolean,
  asAdmin: boolean,
  cdlPermissions: ICDLLists,
): IAccountPermissionsForList => ({
  isAdminAccount,
  asAdmin,
  cdlPermissions,
});
