import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

import { environment } from "@env/environment";

import { Observable } from "rxjs";

import { go } from "@core/store/actions/router-history.action";
import { CoreState } from "@core/store/reducers";
import { showNotificationAction } from "../../notifications/store/actions/notification.action";
import { invitationRedirectWhenCanNotUseAppAction } from "../store/actions/invitation.action";

import { pluckAndCatch } from "@shared/utils/response-formater";

import {
  IInvitationUser,
  IInvitationUserResponse,
} from "../interfaces/invitation";

import { CORE_PATHS } from "@core/constants/core-paths";
import { NOTIFICATION_TYPES } from "@core/constants/notifications";
import { INVITATION_ACTION } from "../constants/invitation";

@Injectable()
export class InvitationService {
  constructor(
    private http: HttpClient,
    private store: Store<CoreState>,
  ) {}

  invitationUser(
    invitation: IInvitationUser,
  ): Observable<IInvitationUserResponse> {
    return this.http
      .patch(environment.api.invitation.user, invitation)
      .pipe(pluckAndCatch);
  }

  verifyInvitation(): Observable<IInvitationUserResponse> {
    return this.http.get(environment.api.dashboard.verify).pipe(pluckAndCatch);
  }

  sendInvitationWithoutNotification(
    token: string,
    action: INVITATION_ACTION,
    resolveCallBack: Function,
  ): void {
    this.invitationUser({ token, action }).subscribe(
      (res: IInvitationUserResponse) => {
        if (res && res.userCanUseApplication) {
          this.store.dispatch(go(["/", CORE_PATHS.DASHBOARD]));
        } else {
          this.store.dispatch(invitationRedirectWhenCanNotUseAppAction(res));
        }

        resolveCallBack();
      },
      (error: any) => {
        this.store.dispatch(
          showNotificationAction({
            message: error.errors["token"],
            type: NOTIFICATION_TYPES.ERROR,
            timeout: 3000,
            canClose: true,
          }),
        );
        resolveCallBack();
      },
    );
  }

  sendInvitationWithNotification(
    token: string,
    action: INVITATION_ACTION,
    resolveCallBack: Function,
  ): void {
    this.invitationUser({ token, action }).subscribe(
      (res: IInvitationUserResponse) => {
        if (res && res.userCanUseApplication) {
          this.store.dispatch(go(["/", CORE_PATHS.DASHBOARD]));
          this.store.dispatch(
            showNotificationAction({
              message: res.message,
              type: NOTIFICATION_TYPES.SUCCESS,
              timeout: 5000,
              canClose: true,
            }),
          );
        } else {
          this.store.dispatch(invitationRedirectWhenCanNotUseAppAction(res));
        }

        resolveCallBack();
      },
      (error: any) => {
        this.store.dispatch(
          showNotificationAction({
            message: error.errors["token"],
            type: NOTIFICATION_TYPES.ERROR,
            timeout: 3000,
            canClose: true,
          }),
        );

        resolveCallBack();
      },
    );
  }
}
