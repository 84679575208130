import { Inject, Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { select, Action, Store } from "@ngrx/store";

import { of, Observable, defer } from "rxjs";
import {
  catchError,
  map,
  switchMap,
  tap,
  withLatestFrom,
} from "rxjs/operators";

import { go } from "@core/store/actions/router-history.action";
import { CoreState } from "@core/store/reducers";
import { ActionWithPayload } from "@shared/interfaces/store";
import { showNotificationAction } from "../../../notifications/store/actions/notification.action";
import { openAssignOwnerInfoPopUpAction } from "../../../profile/store/actions/account-pop-ups.action";
import { getProfileAction } from "../../../profile/store/actions/profile.action";
import * as actions from "../actions/invitation.action";
import { getInvitationExistedUser } from "../selectors/invitation.selector";

import { InvitationService } from "../../services/invitation.service";

import { catchErrorWithErrorType } from "@shared/utils/error-handlers";

import { IServerError } from "@shared/interfaces/server-error";
import {
  IInvitationUser,
  IInvitationUserResponse,
} from "../../interfaces/invitation";
import { ISuccessMessageResponse } from "../../interfaces/user";

import { CORE_PATHS } from "@core/constants/core-paths";
import { NOTIFICATION_TYPES } from "@core/constants/notifications";
import { AUTH_PATHS } from "../../constants/auth-paths";
import { invitationAPICallsAction } from "../actions/auth.action";
import { StorageService } from "@core/services/storage.service";
import { INVITATION_ACTION } from "@modules/auth/constants/invitation";
import { PROFILE_PATHS } from "@modules/profile/constants/profile-route-paths";
import {
  invitationAction,
  invitationWithoutNotificationAction,
  resetInvitationStateAction,
} from "../actions/invitation.action";
import { OKTA_AUTH } from "@okta/okta-angular";
import OktaAuth from "@okta/okta-auth-js";

@Injectable()
export class InvitationEffect {
  constructor(
    private _actions$: Actions,
    private _store: Store<CoreState>,
    private _invitationService: InvitationService,
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
  ) {}

  // INVITATION
  invitation$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.invitationAction),
      switchMap(
        ({
          payload: { token, action, email },
        }: ActionWithPayload<IInvitationUser>) =>
          this._invitationService.invitationUser({ token, action, email }).pipe(
            map((res: IInvitationUserResponse) => {
              if (res && res.userCanUseApplication) {
                this._store.dispatch(go(["/", CORE_PATHS.DASHBOARD]));
                return actions.invitationSuccessAction(res);
              }

              return actions.invitationRedirectWhenCanNotUseAppAction(res);
            }),
            catchError((error: IServerError) =>
              of(actions.invitationErrorAction(error)),
            ),
          ),
      ),
      catchErrorWithErrorType,
    ),
  );

  invitationSuccess$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.invitationSuccessAction),
      switchMap(
        ({
          payload: { message },
        }: ActionWithPayload<ISuccessMessageResponse>) => [
          actions.removeInvitationTokenAction(),
          getProfileAction(),
          showNotificationAction({
            message,
            type: NOTIFICATION_TYPES.SUCCESS,
            timeout: 5000,
            canClose: true,
          }),
        ],
      ),
      catchErrorWithErrorType,
    ),
  );

  invitationErrorNotification$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.invitationErrorAction),
      switchMap(
        ({
          payload: { message },
        }: ActionWithPayload<ISuccessMessageResponse>) => [
          actions.removeInvitationTokenAction(),
          showNotificationAction({
            message,
            type: NOTIFICATION_TYPES.ERROR,
            timeout: 5000,
            canClose: true,
          }),
        ],
      ),
      catchErrorWithErrorType,
    ),
  );

  invitationError$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(
        actions.invitationErrorAction,
        actions.invitationWithoutNotificationErrorAction,
      ),
      map(() => actions.removeInvitationTokenAction()),
      catchErrorWithErrorType,
    ),
  );

  // INVITATION WITHOUT NOTIFICATION
  invitationWithoutNotification$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.invitationWithoutNotificationAction),
      withLatestFrom(this._store.pipe(select(getInvitationExistedUser))),
      switchMap(
        ([_action, { token, action, email, newOwnerId }]: [
          Action,
          IInvitationUser,
        ]) =>
          this._invitationService
            .invitationUser(
              newOwnerId
                ? { token, action, email, newOwnerId }
                : { token, action, email },
            )
            .pipe(
              switchMap((res: IInvitationUserResponse) => {
                if (res && res.userCanUseApplication) {
                  return [
                    go(["/", CORE_PATHS.DASHBOARD]),
                    actions.invitationWithoutNotificationSuccessAction(),
                  ];
                }

                return [actions.invitationRedirectWhenCanNotUseAppAction(res)];
              }),
              catchError((error: IServerError) =>
                of(actions.invitationWithoutNotificationErrorAction(error)),
              ),
            ),
      ),
      catchErrorWithErrorType,
    ),
  );

  invitationWithoutMsgSuccess$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.invitationWithoutNotificationSuccessAction),
      switchMap(() => [
        actions.removeInvitationTokenAction(),
        getProfileAction(),
      ]),
      catchErrorWithErrorType,
    ),
  );

  // INVITATION AND SHOW INFO POP UP
  invitationAndShowPopUp$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.invitationAndShowInfoPopUpAction),
      switchMap(
        ({
          payload: { token, action, newOwnerId },
        }: ActionWithPayload<IInvitationUser>) =>
          this._invitationService
            .invitationUser({ token, action, newOwnerId })
            .pipe(
              map((res: IInvitationUserResponse) => {
                if (res && res.userCanUseApplication) {
                  return actions.invitationAndShowInfoPopUpSuccessAction();
                }

                return actions.invitationRedirectWhenCanNotUseAppAction(res);
              }),
              catchError((error: IServerError) =>
                of(actions.invitationAndShowInfoPopUpErrorAction(error)),
              ),
            ),
      ),
      catchErrorWithErrorType,
    ),
  );

  invitationAndShowPopUpSuccess$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.invitationAndShowInfoPopUpSuccessAction),
      map(() => openAssignOwnerInfoPopUpAction()),
      catchErrorWithErrorType,
    ),
  );

  // INVITATION REDIRECT WHEN CAN NOT USE APP
  invitationRedirectWhenCanNotUseApp$: Observable<Action> = createEffect(() =>
    this._actions$.pipe(
      ofType(actions.invitationRedirectWhenCanNotUseAppAction),
      switchMap(
        ({
          payload: { message },
        }: ActionWithPayload<IInvitationUserResponse>) => [
          go(["/", CORE_PATHS.AUTH, AUTH_PATHS.LOGIN]),
          showNotificationAction({
            message,
            type: NOTIFICATION_TYPES.ERROR,
            timeout: 3000,
            canClose: true,
          }),
        ],
      ),
      catchErrorWithErrorType,
    ),
  );

  setInvitation$: Observable<unknown> = createEffect(
    () =>
      defer(() =>
        this._actions$.pipe(
          ofType(actions.setInvitationTokenAction),
          tap(({ payload }) => {
            this.invitationAPICalls(payload);
          }),
          catchErrorWithErrorType,
        ),
      ),
    { dispatch: false },
  );

  invitationAPICalls(invitation: IInvitationUser) {
    if (
      invitation &&
      invitation.token &&
      invitation.action
      // StorageService.user &&
      // invitation.email === StorageService.user.email
    ) {
      if (
        INVITATION_ACTION.OWNER_KEEP_SEPARATE.toString() ===
        invitation.action.toString()
      ) {
        this._store.dispatch(
          go(["/", CORE_PATHS.ACCOUNT, PROFILE_PATHS.PRODUCTS]),
        );

        // Show only when user is not logged In
        if (!this.oktaAuth.getAccessToken()) {
          this._store.dispatch(
            showNotificationAction({
              message:
                "Please log into the application first and then click on Accept & Keep Separate Accounts link in your invitation Email.",
              canClose: true,
              type: NOTIFICATION_TYPES.INFO,
            }),
          );
        }
      } else if (
        INVITATION_ACTION.OWNER_MERGE.toString() ===
        invitation.action.toString()
      ) {
        this._store.dispatch(invitationAction(invitation));
      } else {
        this._store.dispatch(invitationAction(invitation));
      }
    } else {
      this._store.dispatch(resetInvitationStateAction());
    }
  }
}
